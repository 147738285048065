/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@1.7.0/dist/js/lightgallery.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-autoplay.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-fullscreen.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-hash.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-pager.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-share.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-thumbnail.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-video.min.js
 * - /npm/lightgallery@1.7.0/modules/lg-zoom.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
